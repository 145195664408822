import { Box, Flex, Link as ChakraLink } from "@chakra-ui/react";
import { Fragment } from "react";
import EnvelopeIcon from "../EnvelopeIcon";

const DefaultHelpdeskData = () => {
  return (
    <Fragment>
      <Flex alignItems="center" mt="7">
        <Box width="30px">
          <EnvelopeIcon width="14px" />
        </Box>
        <ChakraLink
          fontWeight="500"
          color="primary"
          href="mailto:helpdesk@allshares.com"
          fontSize="small"
        >
          helpdesk@allshares.com
        </ChakraLink>
      </Flex>
    </Fragment>
  );
};

export default DefaultHelpdeskData;

import {
  ApolloClient,
  gql,
  NormalizedCacheObject,
  useQuery,
} from "@apollo/client";
import { Box, Flex, Link as ChakraLink } from "@chakra-ui/react";
import { GetHelpdeskWithIdQuery } from "apollo/generatedTypes";
import { getQueryState, QueryState } from "components/queryUtil";
import SlideFadeSpinner from "components/SlideFadeSpinner";
import { Fragment } from "react";
import EnvelopeIcon from "../EnvelopeIcon";
import MobilePhoneIcon from "../MobilePhoneIcon";
import DefaultHelpdeskData from "./DefaultHelpdeskData";

const GET_HELPDESK_DATA = gql`
  query getHelpdeskWithId($companyId: Int!) {
    getHelpdeskWithId(companyId: $companyId) {
      email
      phone
    }
  }
`;

const CompanyHelpdeskData = ({
  companyId,
  apolloClient,
}: {
  companyId: number;
  apolloClient: ApolloClient<NormalizedCacheObject>;
}) => {
  const payload = useQuery<GetHelpdeskWithIdQuery>(GET_HELPDESK_DATA, {
    client: apolloClient,
    variables: {
      companyId,
    },
  });

  const queryState = getQueryState(payload);
  const { email, phone } = payload.data?.getHelpdeskWithId ?? {};

  if (queryState === QueryState.ERRORED) {
    console.error(
      "ERROR: Couldn't fetch company details, showing default details instead!"
    );
  }

  return (
    <Fragment>
      <SlideFadeSpinner isVisible={queryState === QueryState.IN_PROGRESS} />

      {queryState === QueryState.ERRORED && <DefaultHelpdeskData />}

      {queryState === QueryState.FINISHED && (
        <Fragment>
          <Flex alignItems="center" mt="7">
            <Box width="30px">
              <EnvelopeIcon width="14px" />
            </Box>
            <ChakraLink
              fontWeight="500"
              href={`mailto:${email}`}
              fontSize="small"
            >
              {email}
            </ChakraLink>
          </Flex>
          <Flex alignItems="center" mt="4">
            <Box width="30px">
              <MobilePhoneIcon width="14px" />
            </Box>
            <ChakraLink
              fontWeight="500"
              href={`phone:${phone}`}
              fontSize="small"
            >
              {phone}
            </ChakraLink>
          </Flex>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CompanyHelpdeskData;

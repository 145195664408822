import { createIcon } from "@chakra-ui/react";

const EnvelopeIcon = createIcon({
  displayName: "EnvelopeIcon",
  viewBox: "0 0 12 10",
  path: (
    <path
      fill="currentColor"
      d="M10.2917 0H1.625C0.727594 0 0 0.727594 0 1.625V8.125C0 9.02241 0.727594 9.75 1.625 9.75H10.2917C11.1891 9.75 11.9167 9.02241 11.9167 8.125V1.625C11.9167 0.727594 11.1891 0 10.2917 0ZM10.2917 1.08333C10.3542 1.08333 10.4144 1.0939 10.4701 1.11353L5.95833 4.72306L1.44652 1.11353C1.50231 1.0939 1.56244 1.08333 1.625 1.08333H10.2917ZM10.2917 8.66667H1.625C1.32586 8.66667 1.08333 8.42414 1.08333 8.125V2.21027L5.61993 5.83957C5.71878 5.91879 5.83849 5.95833 5.95833 5.95833C6.07818 5.95833 6.19789 5.91879 6.29674 5.83957L10.8333 2.21027V8.125C10.8333 8.42414 10.5908 8.66667 10.2917 8.66667Z"
    />
  ),
});

export default EnvelopeIcon;
